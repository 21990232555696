import { apiSlice } from "../api/apiSlice";

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // to get users details
    getProfile: builder.query({
      query: (email) => ({
        url: `/my-profile/${email}`,
        method: "GET",
      }),
      keepUnusedDataFor: 60 * 60 * 24,
      invalidatesTags: ["mobile", "order"],
    }),
    // to get user product orders
    getUserProductOrders: builder.query({
      query: (data) => `my-orders?email=${data.email}&limit=${data.limit}`,
      providesTags: ["order"],
    }),
    // to get user product orders
    getUserTopups: builder.query({
      query: (data) =>
        `my-balance-orders?email=${data.email}&limit=${data.limit}`,
    }),
    // to update mobile number
    updateMobile: builder.mutation({
      query: ({ email, data }) => ({
        url: `/update-phone/${email}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["mobile"],
    }),
    // to order product
    orderProduct: builder.mutation({
      query: (data) => ({
        url: `/insert-product-order`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["order"],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useUpdateMobileMutation,
  useGetUserProductOrdersQuery,
  useOrderProductMutation,
  useGetUserTopupsQuery,
} = authApi;
