import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetMonthWisePaidOrdersQuery } from "../../../Components/redux&rtk/features/productAndRecharges/productAndRechargesApi";
import LoadingPage from "../../../Components/Shared/LoadingPage";
import DashboardContent from "./DashboardContent";
import DayWiseChart from "./DayWiseChart";

const date = new Date().toUTCString().split(" ");
const currentmonthName = date[2];
const currentyear = date[3];

const MonthWise = () => {
  const [month, setMonth] = useState(currentmonthName);
  const [year, setYear] = useState(currentyear);
  const { email } = useSelector((state) => state.auth);
  const { data, isLoading } = useGetMonthWisePaidOrdersQuery({
    adminEmail: email,
    month: month,
    year: year,
  });
  const { result } = data || {};
  const FREEFIREINGAME = "FREE FIRE INGAME";
  const FREEFIREIDCODE = "FREE FIRE IDCODE";
  const FREEFIREPROMO = "FREE FIRE PROMO";
  const FREEFIREINDONESIA = "FREE FIRE INDONESIA";
  const PUBGMOBILE = "PUBG MOBILE";
  const LESSISMORE = "LESS IS MORE";
  const UNIPINVOUCHER = "UNIPIN VOUCHER";
  let FREEFIREINGAMEARRAY = [];
  let FREEFIREIDCODEARRAY = [];
  let FREEFIREPROMOARRAY = [];
  let FREEFIREINDONESIAARRAY = [];
  let PUBGMOBILEARRAY = [];
  let LESSISMOREARRAY = [];
  let UNIPINVOUCHERARRAY = [];
  if (isLoading) return <LoadingPage />;
  //   to push each product in it's own array
  result?.forEach((each) => {
    if (each.productName === FREEFIREINGAME) {
      FREEFIREINGAMEARRAY.push(each);
    }
    if (each.productName === FREEFIREIDCODE) {
      FREEFIREIDCODEARRAY.push(each);
    }
    if (each.productName === FREEFIREPROMO) {
      FREEFIREPROMOARRAY.push(each);
    }
    if (each.productName === FREEFIREINDONESIA) {
      FREEFIREINDONESIAARRAY.push(each);
    }
    if (each.productName === PUBGMOBILE) {
      PUBGMOBILEARRAY.push(each);
    }
    if (each.productName === LESSISMORE) {
      LESSISMOREARRAY.push(each);
    }
    if (each.productName === UNIPINVOUCHER) {
      UNIPINVOUCHERARRAY.push(each);
    }
  });
  const allMonthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const someYear = [
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
  ];
  const totalPaid =
    FREEFIREIDCODEARRAY.length +
    FREEFIREINDONESIAARRAY.length +
    FREEFIREINGAMEARRAY.length +
    FREEFIREPROMOARRAY.length +
    PUBGMOBILEARRAY.length +
    LESSISMOREARRAY.length +
    UNIPINVOUCHERARRAY.length;
  return (
    <div>
      <div>
        <p className="font-semibold py-1 px-4 rounded-full mb-3 text-blue-500 text-center underline text-xl ">
          Monthly Orders
        </p>
        {/* month and year */}
        <div className="flex">
          <p className="font-semibold py-1 px-4 rounded-full  text-gray-800 text-lg">
            <select
              onChange={(e) => setMonth(e.target.value)}
              className="select text-gray-900 text-[15px] select-primary w-full max-w-xs"
            >
              {allMonthName.map((each) => (
                <option key={each} selected={each === currentmonthName}>
                  {each}
                </option>
              ))}
            </select>
          </p>
          <p className="font-semibold py-1 px-4 rounded-full mb-3 text-gray-800 text-lg">
            <select
              onChange={(e) => setYear(e.target.value)}
              className="select text-gray-900 text-[15px] select-primary w-full max-w-xs"
            >
              {someYear.map((each) => (
                <option key={each} selected={each === currentyear}>
                  {each}
                </option>
              ))}
            </select>
          </p>
        </div>
        <div className="my-10 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-5 ">
          <DashboardContent
            name={FREEFIREIDCODE}
            number={FREEFIREIDCODEARRAY.length}
          />
          <DashboardContent
            name={FREEFIREINDONESIA}
            number={FREEFIREINDONESIAARRAY.length}
          />
          <DashboardContent
            name={FREEFIREINGAME}
            number={FREEFIREINGAMEARRAY.length}
          />
          <DashboardContent
            name={FREEFIREPROMO}
            number={FREEFIREPROMOARRAY.length}
          />
          <DashboardContent name={PUBGMOBILE} number={PUBGMOBILEARRAY.length} />
          <DashboardContent name={LESSISMORE} number={LESSISMOREARRAY.length} />
          <DashboardContent
            name={UNIPINVOUCHER}
            number={UNIPINVOUCHERARRAY.length}
          />
          <DashboardContent name={"Total Orders"} number={totalPaid} />
        </div>
      </div>
      <DayWiseChart allOrder={result} />
    </div>
  );
};

export default MonthWise;
