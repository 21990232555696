import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/AuthenticationPages/Login";
import Register from "./pages/AuthenticationPages/Register";
import Footer from "./Components/Shared/Footer";
import Navbar from "./Components/Shared/Navbar";
import HomePage from "./pages/HomePage";
import RequireAuth from "./pages/AuthenticationPages/RequireAuth";
import MyProfile from "./pages/User/MyProfile";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "./firebase.init";
import {
  userLoggedIn,
  userLoggedOut,
} from "./Components/redux&rtk/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import MyWalletFinal from "./pages/User/MyWalletFinal";
import PayWithUddoktaPay from "./pages/User/PayWithUddoktapay";
import MyOrder from "./pages/User/MyOrder";
import BuyProduct from "./pages/BuyProduct";
import RequireModerator from "./pages/AuthenticationPages/RequireModerator";
import AllOrders from "./pages/Admin/AllOrders";
import RequireAdmin from "./pages/AuthenticationPages/RequireAdmin";
import AllRechargeOptions from "./pages/Admin/AllRechargeOptions";
import NotFound from "./pages/NotFound";
import SliderContents from "./pages/Admin/SliderContents";
import Dashboard from "./pages/Admin/Dashboard/Dashboard";
import RequireNotUser from "./pages/AuthenticationPages/RequireNotUser";
import ResetPassword from "./pages/AuthenticationPages/ResetPassword";
import VoucherCodeDetails from "./pages/Admin/VoucherCodeDetails";

function App() {
  const dispatch = useDispatch();
  const [user] = useAuthState(auth);
  const { pathname } = useLocation();
  // console.log(user);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  // to get the user details when a user open the website. this details used in every page where user details needed.
  const { token, loggedIn } = useSelector((state) => state.auth);
  // console.log(loggedIn);
  useEffect(() => {
    if (
      (!pathname.includes("register") || !pathname.includes("login")) &&
      loggedIn !== true
    ) {
      if (user) {
        const email = user?.email;
        const token = localStorage.getItem("accessToken");
        fetch(`https://server.topuphero.com/api/my-profile/${email}`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status === "error") {
              dispatch(userLoggedOut());
            } else {
              dispatch(userLoggedIn(data.result));
            }
          });
      } else {
        dispatch(userLoggedOut());
      }
    }
  }, [dispatch, pathname, user?.email, user, token, loggedIn]);
  return (
    <main className="">
      {/* Navbar */}
      <Navbar />
      <div className="mt-[90px]">
        <Routes>
          {/* Home */}
          <Route path="/" element={<HomePage />}></Route>
          {/* Login */}
          <Route
            path="/login"
            element={
              <RequireNotUser>
                <Login />
              </RequireNotUser>
            }
          ></Route>
          {/* Register */}
          <Route
            path="/register"
            element={
              <RequireNotUser>
                <Register />
              </RequireNotUser>
            }
          ></Route>
          {/* to reset password */}
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          {/* User profile */}
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <MyProfile />
              </RequireAuth>
            }
          ></Route>
          {/* User wallet */}
          <Route
            path="/wallet"
            element={
              <RequireAuth>
                <MyWalletFinal />
              </RequireAuth>
            }
          ></Route>
          {/* add balance */}
          <Route
            path="/add-balance-to-the-wallet"
            element={
              <RequireAuth>
                <PayWithUddoktaPay />
              </RequireAuth>
            }
          ></Route>
          {/* User orders */}
          <Route
            path="/order"
            element={
              <RequireAuth>
                <MyOrder />
              </RequireAuth>
            }
          ></Route>
          {/* Buy game */}
          <Route path="/product/:id" element={<BuyProduct />}></Route>
          {/* 
          for admins and moderators only
          */}
          {/* to get all product orders */}
          <Route
            path="/all-product-order"
            element={
              <RequireAuth>
                <RequireModerator>
                  <AllOrders />
                </RequireModerator>
              </RequireAuth>
            }
          ></Route>
          {/* all recharge options */}
          <Route
            path="/all-recharge-options"
            element={
              <RequireAuth>
                <RequireAdmin>
                  <AllRechargeOptions />
                </RequireAdmin>
              </RequireAuth>
            }
          ></Route>
          {/* Voucher Code */}
          <Route
            path="/voucher-code"
            element={
              <RequireAuth>
                <RequireModerator>
                  <VoucherCodeDetails />
                </RequireModerator>
              </RequireAuth>
            }
          ></Route>
          {/* sliderContents */}
          <Route
            path="/slider-contents"
            element={
              <RequireAuth>
                <RequireAdmin>
                  <SliderContents />
                </RequireAdmin>
              </RequireAuth>
            }
          ></Route>
          {/* Dashboard */}
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <RequireAdmin>
                  <Dashboard />
                </RequireAdmin>
              </RequireAuth>
            }
          ></Route>

          {/* Notfound */}
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
        <Footer />
      </div>
      <ToastContainer />
    </main>
  );
}

export default App;
