import React, { useEffect, useState } from "react";
import AddNewRechargeOption from "../../Components/Modals/AddNewRechargeOption";
import ConfirmDeletePackage from "../../Components/Modals/ConfirmDeletePackage";
import UpdateAmountOfRecharge from "../../Components/Modals/UpdateAmountOfRechargeOptions";
function AllRechargeOptions() {
  const [rechargeOptions, setrechargeOptions] = useState([]);
  const [nameOfProduct, setNameOfProduct] = useState("FREE FIRE INGAME");
  const [update, setUpdate] = useState(""); // to update the result depending on admin's update status
  const [order, setOrder] = useState(null);
  const [modal, setModal] = useState(null); //  just to open delete modal
  const [add, setAdd] = useState(null); // just to show modal of add new recharge package
  useEffect(() => {
    fetch(
      `https://server.topuphero.com/api/all-recharge-options/${nameOfProduct}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => setrechargeOptions(data.result));
  }, [nameOfProduct, update]);
  return (
    <section className="pt-2 w-[90%] text-gray-900 mx-auto rounded-md">
      <div className="mt-10 md:mt-10 border-t border-gray-200 md:border-none">
        <div className="!px-0 md:!px-5 md:my-7">
          <div className="bg-white flex flex-col">
            <div className="border-gray-200 md:rounded-md pt-4 relative">
              <div className="flex justify-between items-center">
                <p className="font-semibold py-1 px-4 rounded-full mb-3 text-primary text-2xl ">
                  <span>All Recharge Options</span>{" "}
                  <label
                    htmlFor="add-new-recharge-package"
                    onClick={() => setAdd(100)}
                    className="bg-blue-300 rounded-lg px-2 cursor-pointer py-1 w-fit"
                  >
                    +
                  </label>
                </p>
                <p className="font-semibold py-1 px-4 rounded-full mb-3 text-gray-800 text-lg">
                  <select
                    onChange={(e) => setNameOfProduct(e.target.value)}
                    className="select text-gray-900 text-[15px] select-primary w-full max-w-xs"
                  >
                    <option defaultValue>FREE FIRE INGAME</option>
                    <option>FREE FIRE IDCODE</option>
                    <option>FREE FIRE PROMO</option>
                    <option>PUBG MOBILE</option>
                    <option>FREE FIRE INDONESIA</option>
                    <option>LESS IS MORE</option>
                    <option>UNIPIN VOUCHER</option>
                  </select>
                </p>
              </div>
              <div className="">
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white  text-xl">
                    <thead className="">
                      <tr className="text-left">
                        <th className="p-3 border">Index</th>
                        <th className="p-3 border">Product Name</th>
                        <th className="p-3 border">Recharge Pack Name</th>
                        <th className="p-3 border">Amount</th>
                        <th className="p-3 border">Action</th>
                        <th className="p-3 border">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rechargeOptions?.map((each, Index) => (
                        <tr
                          key={each._id}
                          className="border-b  border-opacity-20 "
                        >
                          <td className="p-3 border">
                            <p>{Index + 1}</p>
                          </td>
                          <td className="p-3 border">
                            <p>{each.productName}</p>
                          </td>
                          <td className="p-3 border">
                            <p>{each.name}</p>
                          </td>
                          <td className="p-3 border">
                            <p>{each.amount}</p>
                          </td>
                          <td className="p-3 border">
                            <label
                              htmlFor="update-amount-of-recharge-options"
                              onClick={() => setOrder(each)}
                              className="bg-blue-300 rounded-lg px-2 cursor-pointer py-1 w-fit"
                            >
                              Update
                            </label>
                          </td>
                          <td className="p-3 border">
                            <p>
                              <label
                                htmlFor="delete-package"
                                onClick={() => setModal(each)}
                                className={`bg-red-800 px-2 py-1 cursor-pointer rounded-full text-[15px] text-white`}
                              >
                                Delete
                              </label>
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {order && <UpdateAmountOfRecharge order={order} setUpdate={setUpdate} />}
      {add && <AddNewRechargeOption setUpdate={setUpdate} />}
      {modal && rechargeOptions && (
        <ConfirmDeletePackage
          packageName={modal}
          rechargeOptions={rechargeOptions}
          setrechargeOptions={setrechargeOptions}
        />
      )}
    </section>
  );
}

export default AllRechargeOptions;
