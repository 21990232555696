import React from "react";

const DashboardContent = ({ name, number }) => {
  return (
    <div className="p-4 bg-white shadow-lg rounded-2xl w-40 dark:bg-gray-800">
      <div className="flex items-center">
        <p className="my-2 text-2xl font-bold text-left text-gray-800 dark:text-white">
          {name}
        </p>
      </div>
      <div className="flex flex-col justify-start">
        <p className="my-2 text-xl font-bold text-left text-gray-800 dark:text-white">
          Paid: {number}
        </p>
      </div>
    </div>
  );
};

export default DashboardContent;
