import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyA7S5fk_HVO6uUQFHPktqZDyQpeSePJ83k",
  authDomain: "topuphero-3d7c3.firebaseapp.com",
  projectId: "topuphero-3d7c3",
  storageBucket: "topuphero-3d7c3.appspot.com",
  messagingSenderId: "193571643102",
  appId: "1:193571643102:web:8d2a43d67c6a7a1d11ac4f",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export default auth;
