import React from "react";
import { useGetAllProductsQuery } from "../redux&rtk/features/productAndRecharges/productAndRechargesApi";
import LoadingPage from "./LoadingPage";
import Product from "./Product";

function Products() {
  const { data, isLoading } = useGetAllProductsQuery();
  const { result: products } = data || {};
  if (isLoading) {
    return <LoadingPage />;
  }
  return (
    <section className="w-[86%] md:w-[88%] bg-white mt-5 mb-20">
      <div>
        <div className="mt-3 mb-8 flex justify-center">
          <p className="py-2.5 w-[250px] text-primary border-primary px-4 text-center  cursor-pointer font-bold text-2xl flex justify-center items-center gap-3 rounded-3xl duration-200 select-none border border-primary-500 !text-primary-500">
            <span>
              <span className="flex items-center gap-3">
                <span>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 16 16"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"></path>
                  </svg>
                </span>
                All Products
              </span>
            </span>
          </p>
        </div>
        <div className="mt-12 grid grid-cols-2 md:grid-cols-3 gap-[30px] gap-y-[60px] md:gap-x-[90px] md:gap-y-[74px] lg:grid-cols-4">
          {products?.map((product) => {
            if (product?.active === true) {
              return <Product key={product._id} product={product} />;
            }
          })}
        </div>
      </div>
    </section>
  );
}

export default Products;
