import React, { useEffect } from "react";
import { useState } from "react";
import auth from "../../firebase.init";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPen } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useUpdatePassword } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useUpdateMobileMutation } from "../../Components/redux&rtk/features/auth/authApi";
import { useDispatch, useSelector } from "react-redux";
import { updateMobileNumber } from "../../Components/redux&rtk/features/auth/authSlice";

function MyProfile() {
  const { auth: profile } = useSelector((state) => state);
  // to update password
  const dispatch = useDispatch();
  const [password, setPassword] = useState(null);
  const [confpassword, setconfPassword] = useState(null);
  const [updatePassword, error] = useUpdatePassword(auth);
  // to get profile from db
  // const [profile, setProfile] = useState({});
  // to make collapsable section
  const [open, setOpen] = useState(false);
  const [openUp, setOpenUp] = useState(false); // to collapse the update password div
  const [phone, setPhone] = useState(null);
  // to navigate the user to the reset password page
  const navigate = useNavigate();
  const [updateMobile, { isLoading: isUpdating, isSuccess, isError }] =
    useUpdateMobileMutation();
  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
      dispatch(updateMobileNumber(phone));
      toast.success("Your phone number is updated.");
    }
    if (isError) {
      toast.error("Please input valid phone number.");
    }
  }, [isSuccess, isError, dispatch, phone]);
  // // to navigate the user to the reset password page
  // const navigateToRePass = () => {
  //   navigate("/reset-password");
  // };
  const regexForNumber = /^-?\d+\.?\d*$/;
  // for updating phone number
  const updatePhone = (e) => {
    e.preventDefault();
    if (regexForNumber.test(phone) === true && phone.length === 11) {
      const data = {
        phone: phone,
      };
      const email = profile.email;
      updateMobile({ email, data });
    }
  };
  // to update password
  const updatePass = async (e) => {
    e.preventDefault();
    if (!password || !confpassword) {
      toast.error("Please input the form properly.");
    }
    if (password && confpassword) {
      if (password !== confpassword) {
        toast.error("Password doesn't matched.");
      }
      if (password === confpassword) {
        if (password.length < 6) {
          toast.error("Password should contain at least 6 characters.");
        }
        await updatePassword(password);
        setOpenUp(false);
        toast.success("Password updated.");
      }
      if (error?.message === "Firebase: Error (auth/requires-recent-login).") {
        toast.error("Password doesn't matched.");
      }
    }
  };
  return (
    <section className="flex justify-center">
      <div className="pb-5 w-[90%] mx-auto md:pb-7 bg-gray-50 min-h-[80vh]">
        <div className="bg-primary h-[150px] w-full"></div>
        <div className="mt-[-75px]">
          <div className="flex flex-col items-center">
            <div
              className="overflow-hidden 
          h-[150px] w-[150px] rounded-full bg-gray-100 flex-shrink-0 select-none border-4 border-white"
            >
              <img
                loading="lazy"
                src={
                  profile?.photo ||
                  "https://img.freepik.com/premium-vector/gamer-youtuber-gaming-avatar-with-headphones-esport-logo_8169-260.jpg"
                }
                className="w-full h-full object-cover"
                alt="?"
              />
            </div>
            <h1 className="_h2 !font-bold capitalize mt-4 text-gray-900">
              {profile?.name}
            </h1>
            <div className="mt-6 mx-auto text-gray-900 flex flex-col gap-4 w-full items-center">
              <div className="flex items-center justify-between flex-wrap w-full md:w-[450px] bg-white rounded-md overflow-hidden px-4 md:px-5 py-3 shadow-md">
                <span className="text-base md:text-lg font-semibold text-gray-600">
                  User Id:
                </span>
                <span>{profile?._id}</span>
              </div>
              <div className="flex items-center justify-between flex-wrap w-full md:w-[450px] bg-white rounded-md overflow-hidden px-4 md:px-5 py-3 shadow-md">
                <span className="text-base md:text-lg font-semibold text-gray-600">
                  Email:
                </span>
                <span>{profile?.email}</span>
              </div>
              <div className="flex flex-col text-gray-900 items-center justify-between flex-wrap w-full md:w-[450px] bg-white rounded-md px-4 md:px-5 py-3 shadow-md relative !overflow-visible">
                <div className="flex justify-between items-center w-full">
                  {" "}
                  <span className="md:text-lg w-full font-semibold text-gray-900">
                    Phone:
                  </span>
                  <span>{isUpdating ? "Updating..." : profile?.mobile}</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="absolute top-0 right-0 -translate-y-1/2 translate-x-1/2">
                    <button
                      onClick={() => setOpen(!open)}
                      className="primary bg-primary px-3 py-2 text-[14px]  text-sm rounded-full duration-200 ease-in-out font-medium border outline-none no-underline select-none  inline-block disabled:opacity-50 disabled:pointer-events-none active:opacity-60 !w-[25px] !h-[25px] !p-[0.5px]  relative"
                    >
                      <FontAwesomeIcon icon={open ? faXmark : faPen} />
                    </button>
                  </div>
                </div>
              </div>
              {/* Update Phone Number Section */}
              {open && (
                <div className="flex flex-col mt-[-23px] text-gray-900 items-center justify-between flex-wrap w-full md:w-[450px] bg-white rounded-md px-4 md:px-5 py-3 shadow-md relative !overflow-visible">
                  <div className="w-full mt-6">
                    <form className="space-y-4">
                      <div>
                        <div className="inline-block w-full">
                          <label className="relative block w-full">
                            <div className="relative inline-block w-full">
                              <input
                                autoComplete="off"
                                type="text"
                                onChange={(e) => {
                                  setPhone(e.target.value);
                                }}
                                name="phone"
                                className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500  px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer   "
                                placeholder="Your phone"
                              />
                              {(regexForNumber.test(phone) !== true ||
                                phone.length !== 11) && (
                                <small className="text-red-500">
                                  Invalid phone number
                                </small>
                              )}
                            </div>
                          </label>
                        </div>
                      </div>
                      <button
                        onClick={updatePhone}
                        className="bg-primary text-white px-3 py-2 text-[14px] text-sm rounded-md duration-200 ease-in-out font-medium border outline-none no-underline select-none disabled:opacity-50 disabled:pointer-events-none active:opacity-60 w-full block  relative"
                        type="submit"
                      >
                        <span className="flex items-center h-full w-full justify-center gap-1.5 ">
                          Update Phone
                        </span>
                      </button>
                    </form>
                  </div>
                </div>
              )}
              {/*  */}

              <div className="flex tems-center justify-between flex-wrap w-full md:w-[450px] bg-white rounded-md overflow-hidden px-4 md:px-5 py-3 shadow-md !flex-col">
                <div className="flex items-center justify-between w-full">
                  <span className="text-base md:text-lg font-semibold text-gray-600">
                    Change Password
                  </span>
                  <button
                    onClick={() => setOpenUp(!openUp)}
                    className="bg-primary px-3 py-2 text-[14px] text-sm rounded-md duration-200 ease-in-out font-medium border outline-none no-underline select-none  inline-block disabled:opacity-50 disabled:pointer-events-none active:opacity-60 extra_small  relative"
                  >
                    <span className="flex items-center h-full w-full justify-center gap-1.5 ">
                      {openUp ? "Close" : "Change"}
                    </span>
                  </button>
                </div>
              </div>
              {/* Update Password Section  */}
              {openUp && (
                <div className="flex mt-[-25px] justify-between flex-wrap w-full md:w-[450px] bg-white rounded-md overflow-hidden px-4 md:px-5 py-3 shadow-md !flex-col !items-start">
                  <div className="mt-5 w-full">
                    <div className="mb-4">
                      <div className="inline-block w-full">
                        <label className="relative block w-full">
                          <div className="relative inline-block w-full">
                            <input
                              type="text"
                              autoComplete="off"
                              name="old_password"
                              className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500  px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer   "
                              placeholder="Old password"
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                    <form className="space-y-4">
                      <div>
                        <div className="inline-block w-full">
                          <label className="relative block w-full">
                            <div className="relative inline-block w-full">
                              <input
                                type="text"
                                autoComplete="off"
                                onChange={(e) => setPassword(e.target.value)}
                                name="new_password"
                                className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500  px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer   "
                                placeholder="New password"
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                      <div>
                        <div className="inline-block w-full">
                          <label className="relative block w-full">
                            <div className="relative inline-block w-full">
                              <input
                                type="text"
                                autoComplete="off"
                                onChange={(e) =>
                                  setconfPassword(e.target.value)
                                }
                                name="confirm_password"
                                className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500  px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer   "
                                placeholder="Confirm password"
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                      <button
                        onClick={updatePass}
                        className="bg-primary primary px-3 py-2 text-[14px] text-white text-sm rounded-md duration-200 ease-in-out font-medium border outline-none no-underline select-none  disabled:opacity-50 disabled:pointer-events-none active:opacity-60 w-full block  relative"
                        type="submit"
                      >
                        <span className="flex items-center h-full w-full justify-center gap-1.5 ">
                          Change Password
                        </span>
                      </button>
                    </form>
                    <span
                      onClick={() => navigate("/reset-password")}
                      className="flex cursor-pointer hover:underline text-primary justify-end mt-4"
                    >
                      Forgot password?
                    </span>
                  </div>
                </div>
              )}
              {/*  */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MyProfile;
