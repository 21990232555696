import React, { useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingPage from "../../Components/Shared/LoadingPage";

function PayWithUddoktaPay() {
  const { auth: profile } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const amountRef = useRef();
  // to show loading
  if (loading) return <LoadingPage />;
  //   to add money
  const addMoney = async (e) => {
    e.preventDefault();
    let date = new Date();
    date = date.toLocaleString();
    const regexForEmail = /^[1-9]+[0-9]*$/;
    const amount = amountRef.current.value;
    const { _id, name, email } = profile;
    const userId = _id;

    if (regexForEmail.test(amount)) {
      const money = parseInt(amount);
      setLoading(true);
      const detailsOfTopup = {
        date: date,
        email: email,
        name: name,
        amount: money,
        userID: userId,
      };
      fetch("https://server.topuphero.com/api/add-balance-to-wallet", {
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(detailsOfTopup),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          if (data?.data?.status) {
            window.location.replace(data.data.payment_url);
          } else {
            setLoading(false);
            toast.error("Please try again after some times.");
          }
        });
    } else {
      toast.error("Enter a valid number.");
    }
  };

  return (
    <section className="w-[90%] my-10 mt-[150px] mx-auto flex justify-center items-center flex-col">
      <h3 className="text-xl text-gray-900">
        Recharge in your wallet instantly
      </h3>
      <div className="w-full sm:w-[90%] md:w-[50%]  relative p-2 text-gray-900">
        <div className="mt-5">
          <h3 className="text-xl">Amount</h3>
          <form className="flex flex-col gap-4">
            <div>
              <div className="inline-block w-full">
                <label className="relative block w-full">
                  <div className="relative inline-block w-full">
                    <input
                      type="text"
                      name="identity"
                      autoComplete="off"
                      ref={amountRef}
                      className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500  px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer   "
                      placeholder="Enter amount"
                    />
                  </div>
                </label>
              </div>
            </div>
            <button
              onClick={addMoney}
              className="primary bg-primary text-white px-3 py-2 text-[14px] text-sm rounded-md duration-200 ease-in-out font-medium border outline-none no-underline select-non disabled:opacity-50 disabled:pointer-events-none active:opacity-60 w-full  relative"
              type="submit"
            >
              <span className="flex items-center h-full w-full justify-center gap-1.5 ">
                Instant Add Money
              </span>
            </button>
          </form>
        </div>
        <div className="mt-5">
          <p className="text-center">How to add money instantly?</p>
          <iframe
            className="w-full mt-5"
            width="320"
            height="300"
            src="https://www.youtube.com/embed/Fi0nHYEPyCQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default PayWithUddoktaPay;
