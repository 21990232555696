import React from "react";
import Products from "../Components/Shared/Products";
import Slider from "../Components/Slider/Slider";

function HomePage() {
  return (
    <section>
      <div className="flex items-center flex-col">
        <Slider />
        <Products />
      </div>
    </section>
  );
}

export default HomePage;
