import { apiSlice } from "../api/apiSlice";

export const productAndRechargesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllProducts: builder.query({
      query: () => `/all-products`,
      keepUnusedDataFor: 600,
    }),
    getProduct: builder.query({
      query: (id) => `/product/${id}`,
    }),
    getRechargePackage: builder.query({
      query: (productName) => `/all-recharge-options/${productName}`,
    }),
    getAllPaidOrders: builder.query({
      query: (adminEmail) =>
        `/admin/paid-product-orders?adminEmail=${adminEmail}`,
    }),
    getLast24HoursPaidOrders: builder.query({
      query: (adminEmail) =>
        `/admin/paid-product-orders-for-last-24-hours?adminEmail=${adminEmail}`,
    }),
    getLastWeekPaidOrders: builder.query({
      query: (adminEmail) =>
        `/admin/paid-product-orders-for-last-7-days?adminEmail=${adminEmail}`,
    }),
    getMonthWisePaidOrders: builder.query({
      query: (data) =>
        `/admin/paid-product-orders-monthwise?adminEmail=${data?.adminEmail}&month=${data?.month}&year=${data?.year}`,
    }),
    addSliderContent: builder.mutation({
      query: (data) => ({
        url: `/admin/insert-slider-content`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["add"],
    }),
    getSliderContents: builder.query({
      query: () => `/all-slider-contents`,
      keepUnusedDataFor: 600,
      providesTags: ["delete", "add"],
    }),
    deleteContent: builder.mutation({
      query: (data) => ({
        url: `admin/delete-slider-content?id=${data.id}&adminEmail=${data.adminEmail}`,
        method: "DELETE",
      }),
      invalidatesTags: ["delete"],
    }),
  }),
});

export const {
  useGetAllProductsQuery,
  useGetRechargePackageQuery,
  useGetProductQuery,
  useAddSliderContentMutation,
  useGetSliderContentsQuery,
  useDeleteContentMutation,
  useGetAllPaidOrdersQuery,
  useGetLast24HoursPaidOrdersQuery,
  useGetLastWeekPaidOrdersQuery,
  useGetMonthWisePaidOrdersQuery,
} = productAndRechargesApi;
