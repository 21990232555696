import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts";

const DayWiseChart = ({ allOrder }) => {
  // date of a month
  let one = 0;
  let two = 0;
  let three = 0;
  let four = 0;
  let five = 0;
  let six = 0;
  let seven = 0;
  let eight = 0;
  let nine = 0;
  let ten = 0;
  let eleven = 0;
  let twelve = 0;
  let thirteen = 0;
  let fourteen = 0;
  let fifteen = 0;
  let sixteen = 0;
  let seventeen = 0;
  let eighteen = 0;
  let nineteen = 0;
  let twenty = 0;
  let twentyOne = 0;
  let twentyTwo = 0;
  let twentyThree = 0;
  let twentyFour = 0;
  let twentyFive = 0;
  let twentySix = 0;
  let twentySeven = 0;
  let twentyEight = 0;
  let twentyNine = 0;
  let thirty = 0;
  let thirtyOne = 0;

  allOrder?.forEach((each) => {
    if (each.timestampDetails.day === "01") {
      return (one = one + 1);
    }
    if (each.timestampDetails.day === "02") {
      return (two = two + 1);
    }
    if (each.timestampDetails.day === "03") {
      return (three = three + 1);
    }
    if (each.timestampDetails.day === "04") {
      return (four = four + 1);
    }
    if (each.timestampDetails.day === "05") {
      return (five = five + 1);
    }
    if (each.timestampDetails.day === "06") {
      return (six = six + 1);
    }
    if (each.timestampDetails.day === "07") {
      return (seven = seven + 1);
    }
    if (each.timestampDetails.day === "08") {
      return (eight = eight + 1);
    }
    if (each.timestampDetails.day === "09") {
      return (nine = nine + 1);
    }
    if (each.timestampDetails.day === "10") {
      return (ten = ten + 1);
    }
    if (each.timestampDetails.day === "11") {
      return (eleven = eleven + 1);
    }
    if (each.timestampDetails.day === "12") {
      return (twelve = twelve + 1);
    }
    if (each.timestampDetails.day === "13") {
      return (thirteen = thirteen + 1);
    }
    if (each.timestampDetails.day === "14") {
      return (fourteen = fourteen + 1);
    }
    if (each.timestampDetails.day === "15") {
      return (fifteen = fifteen + 1);
    }
    if (each.timestampDetails.day === "16") {
      return (sixteen = sixteen + 1);
    }
    if (each.timestampDetails.day === "17") {
      return (seventeen = seventeen + 1);
    }
    if (each.timestampDetails.day === "18") {
      return (eighteen = eighteen + 1);
    }
    if (each.timestampDetails.day === "19") {
      return (nineteen = nineteen + 1);
    }
    if (each.timestampDetails.day === "20") {
      return (twenty = twenty + 1);
    }
    if (each.timestampDetails.day === "21") {
      return (twentyOne = twentyOne + 1);
    }
    if (each.timestampDetails.day === "22") {
      return (twentyTwo = twentyTwo + 1);
    }
    if (each.timestampDetails.day === "23") {
      return (twentyThree = twentyThree + 1);
    }
    if (each.timestampDetails.day === "24") {
      return (twentyFour = twentyFour + 1);
    }
    if (each.timestampDetails.day === "25") {
      return (twentyFive = twentyFive + 1);
    }
    if (each.timestampDetails.day === "26") {
      return (twentySix = twentySix + 1);
    }
    if (each.timestampDetails.day === "27") {
      return (twentySeven = twentySeven + 1);
    }
    if (each.timestampDetails.day === "28") {
      return (twentyEight = twentyEight + 1);
    }
    if (each.timestampDetails.day === "29") {
      return (twentyNine = twentyNine + 1);
    }
    if (each.timestampDetails.day === "30") {
      return (thirty = thirty + 1);
    }
    if (each.timestampDetails.day === "31") {
      return (thirtyOne = thirtyOne + 1);
    }
  });
  // chart data
  const chartResult = [
    {
      date: "01",
      total: one,
    },
    {
      date: "02",
      total: two,
    },
    {
      date: "03",
      total: three,
    },
    {
      date: "04",
      total: four,
    },
    {
      date: "05",
      total: five,
    },
    {
      date: "06",
      total: six,
    },
    {
      date: "07",
      total: seven,
    },
    {
      date: "08",
      total: eight,
    },
    {
      date: "09",
      total: nine,
    },
    {
      date: "10",
      total: ten,
    },
    {
      date: "11",
      total: eleven,
    },
    {
      date: "12",
      total: twelve,
    },
    {
      date: "13",
      total: thirteen,
    },
    {
      date: "14",
      total: fourteen,
    },
    {
      date: "15",
      total: fifteen,
    },
    {
      date: "16",
      total: sixteen,
    },
    {
      date: "17",
      total: seventeen,
    },
    {
      date: "18",
      total: eighteen,
    },
    {
      date: "19",
      total: nineteen,
    },
    {
      date: "20",
      total: twenty,
    },
    {
      date: "21",
      total: twentyOne,
    },
    {
      date: "22",
      total: twentyTwo,
    },
    {
      date: "23",
      total: twentyThree,
    },
    {
      date: "24",
      total: twentyFour,
    },
    {
      date: "25",
      total: twentyFive,
    },
    {
      date: "26",
      total: twentySix,
    },
    {
      date: "27",
      total: twentySeven,
    },
    {
      date: "28",
      total: twentyEight,
    },
    {
      date: "29",
      total: twentyNine,
    },
    {
      date: "30",
      total: thirty,
    },
    {
      date: "31",
      total: thirtyOne,
    },
  ];
  return (
    <div className="hidden lg:flex items-center justify-center">
      <BarChart width={1000} height={300} data={chartResult}>
        <XAxis dataKey="date" stroke="#060606" />
        <YAxis stroke="#060606" />
        <Tooltip />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <Bar dataKey="total" fill="#19b3f1" barSize={30} />
      </BarChart>
    </div>
  );
};

export default DayWiseChart;
