import React from "react";
import Last24HoursPaidOrders from "./Last24HoursPaidOrders";
import LastWeekPaidOrders from "./LastWeekPaidOrders";
import MonthWise from "./MonthWisePaidOrders";
import TotalPaidOrders from "./TotalPaidOrders";

const Dashboard = () => {
  return (
    <section className="pt-2 w-[90%] text-gray-900 mx-auto rounded-md">
      <p className="font-semibold py-1 px-4 rounded-full mb-3 text-primary text-center text-2xl ">
        DASHBOARD
      </p>

      <Last24HoursPaidOrders />
      <LastWeekPaidOrders />
      <MonthWise />
      <TotalPaidOrders />
    </section>
  );
};

export default Dashboard;
