import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  _id: undefined,
  name: undefined,
  email: undefined,
  photo: undefined,
  mobile: undefined,
  role: undefined,
  isAdmin: false,
  balance: 0,
  loggedIn: false,
  token: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userLoggedIn: (state, action) => {
      state._id = action.payload._id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.photo = action.payload.photo;
      state.mobile = action.payload.mobile;
      state.role = action.payload.role;
      state.isAdmin = action.payload.isAdmin;
      state.isModerator = action.payload.isModerator;
      state.balance = action.payload.balance;
      state.loggedIn = true;
    },
    userLoggedOut: (state) => {
      state.name = undefined;
      state.email = undefined;
      state.photo = undefined;
      state.mobile = undefined;
      state.role = undefined;
      state.balance = 0;
      state.token = undefined;
      state.isAdmin = undefined;
      state.loggedIn = false;
    },
    updateToken: (state, action) => {
      state.token = action.payload;
    },
    updateBalance: (state, action) => {
      state.balance = state.balance - action.payload;
    },
    updateMobileNumber: (state, action) => {
      state.mobile = action.payload;
    },
  },
});

export const {
  userLoggedIn,
  userLoggedOut,
  updateToken,
  updateBalance,
  updateMobileNumber,
} = authSlice.actions;
export default authSlice.reducer;
