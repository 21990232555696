import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UpdateOrderStatus from "../../Components/Modals/UpdateOrderStatus";
function AllOrders() {
  const { auth: user } = useSelector((state) => state);
  const [allOrder, setAllOrder] = useState([]);
  const [update, setUpdate] = useState(""); // to update the result depending on admin's update status
  const [name, setName] = useState("FREE FIRE IDCODE");
  const [status, setStatus] = useState("Pending");
  const [order, setOrder] = useState(null);
  useEffect(() => {
    fetch(
      `https://server.topuphero.com/api/admin/product-orders?productName=${name}&status=${status}&adminEmail=${user?.email}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setAllOrder(data.result);
      });
  }, [name, update, status, user?.email]);
  //   console.log(allOrder);

  return (
    <section className="pt-2 w-[90%] text-gray-900 mx-auto rounded-md">
      <div className="mt-10 md:mt-10 border-t border-gray-200 md:border-none">
        <div className="!px-0 md:!px-5 md:my-7">
          <div className="bg-white flex flex-col">
            <div className="border-gray-200 md:rounded-md pt-4 relative">
              <div className="flex justify-between items-center">
                <p className="font-semibold py-1 px-4 rounded-full mb-3 text-primary text-2xl ">
                  Product Orders
                </p>
                <div className="flex">
                  <p className="font-semibold py-1 px-4 rounded-full mb-3 text-gray-800 text-lg">
                    <select
                      onChange={(e) => setStatus(e.target.value)}
                      className="select text-gray-900 text-[15px] select-primary w-full max-w-xs"
                    >
                      <option selected>Pending</option>
                      <option>Paid</option>
                      <option>Cancelled</option>
                    </select>
                  </p>
                  <p className="font-semibold py-1 px-4 rounded-full mb-3 text-gray-800 text-lg">
                    <select
                      onChange={(e) => setName(e.target.value)}
                      className="select text-gray-900 text-[15px] select-primary w-full max-w-xs"
                    >
                      <option selected>FREE FIRE IDCODE</option>
                      <option>PUBG MOBILE</option>
                      <option>FREE FIRE INGAME</option>
                      <option>FREE FIRE PROMO</option>
                      <option>FREE FIRE INDONESIA</option>
                      <option>LESS IS MORE</option>
                      <option>UNIPIN VOUCHER</option>
                    </select>
                  </p>
                </div>
              </div>
              <div className="">
                <div className="overflow-x-auto">
                  {/* if the request is for getting free fire ingame orders */}
                  {name === "FREE FIRE INGAME" && (
                    <table className="min-w-full bg-white text-xl">
                      <thead className="">
                        <tr className="text-left">
                          <th className="p-3 border">OrderId</th>
                          <th className="p-3 border">Buyer Email</th>
                          <th className="p-3 border">Service</th>
                          <th className="p-3 border">Gmail/Facebook Number</th>
                          <th className="p-3 border">Password</th>
                          <th className="p-3 border">Backup Code</th>
                          <th className="p-3 border">Recharge</th>
                          <th className="p-3 border">Money</th>
                          <th className="p-3 border">Status</th>
                          <th className="p-3 border">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allOrder?.map((each, Index) => (
                          <tr
                            key={each._id}
                            className="border-b  border-opacity-20 "
                          >
                            <td className="p-3 border">
                              <p>#{each.orderId}</p>
                            </td>
                            <td className="p-3 border">
                              <p>{each.requestEmail}</p>
                            </td>
                            <td className="p-3 border">
                              <p>{each.optionValue}</p>
                            </td>

                            <td className="p-3 border">
                              <p>{each.emailOrNumber}</p>
                            </td>
                            <td className="p-3 border">
                              <p>{each.password}</p>
                            </td>
                            <td className="p-3 border">
                              <p>{each.backupCode}</p>
                            </td>
                            <td className="p-3 border">
                              <p>{each.recharge}</p>
                            </td>
                            <td className="p-3 border">
                              <p>{each.money}</p>
                            </td>
                            <td
                              className={`
                              } p-3 border`}
                            >
                              <p
                                className={`px-2 py-1 rounded-md ${
                                  each.paid
                                    ? "bg-green-500"
                                    : each.status === "এখনো পরিশোধ করা হয়নি। "
                                    ? "bg-red-400"
                                    : "bg-yellow-400"
                                }`}
                              >
                                {each.statusTwo}
                              </p>
                            </td>
                            <td className="p-3 border">
                              <label
                                htmlFor="update-status-of-product-order"
                                onClick={() => setOrder(each)}
                                className="bg-red-300 rounded-lg px-2 cursor-pointer py-1 w-fit"
                              >
                                Update
                              </label>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  {/* If the request is not for free fire in game */}
                  {name !== "FREE FIRE INGAME" && (
                    <table className="min-w-full bg-white  text-xl">
                      <thead className="">
                        <tr className="text-left">
                          <th className="p-3 border">OrderId</th>
                          <th className="p-3 border">Buyer Email</th>
                          <th className="p-3 border">
                            {name === "UNIPIN VOUCHER"
                              ? "Unipin Voucher"
                              : "Player ID"}
                          </th>
                          <th className="p-3 border">Recharge</th>
                          <th className="p-3 border">Money</th>
                          <th className="p-3 border">Status</th>
                          <th className="p-3 border">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allOrder?.map((each, Index) => (
                          <tr
                            key={each._id}
                            className="border-b  border-opacity-20 "
                          >
                            <td className="p-3 border">
                              <p>#{each.orderId}</p>
                            </td>
                            <td className="p-3 border">
                              <p>{each.requestEmail}</p>
                            </td>
                            <td className="p-3 border">
                              <p>
                                {name === "UNIPIN VOUCHER"
                                  ? `${each.voucherPin}`
                                  : `${each.playerID}`}
                              </p>
                            </td>
                            <td className="p-3 border">
                              <p>{each.recharge}</p>
                            </td>
                            <td className="p-3 border">
                              <p>{each.money}</p>
                            </td>
                            <td
                              className={`
                              } p-3 border`}
                            >
                              <p
                                className={`px-2 py-1 rounded-md ${
                                  each.paid
                                    ? "bg-green-500"
                                    : each.status === "এখনো পরিশোধ করা হয়নি। "
                                    ? "bg-red-400"
                                    : "bg-yellow-400"
                                }`}
                              >
                                {each.statusTwo}
                              </p>
                            </td>
                            <td className="p-3 border">
                              <label
                                htmlFor="update-status-of-product-order"
                                onClick={() => setOrder(each)}
                                className="bg-blue-300 rounded-lg px-2 cursor-pointer py-1 w-fit"
                              >
                                Update
                              </label>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {order && <UpdateOrderStatus setUpdate={setUpdate} order={order} />}
    </section>
  );
}

export default AllOrders;
