import React from "react";

function FreeFireInGameAcc({
  optionValue,
  setOptionValue,
  setPassword,
  setEmailOrNumber,
  setBackupCode,
}) {
  return (
    <section className="order_box_bottom">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="mt-[-5px]">
          <label htmlFor="accounttype" className="block ">
            Account Type
          </label>
          <select
            onChange={(e) => setOptionValue(e.target.value)}
            className="focus:border-2 focus:border-black w-full !py-2.5 "
            name="accounttype"
          >
            <option value="Facebook">Select an option</option>
            <option value="Facebook">Facebook</option>
            <option value="Gmail">Gmail</option>
          </select>
        </div>
        <div className="">
          <div className="inline-block w-full">
            <label className="relative block w-full">
              <span className="text-[14px]  text-slate-500 font-semibold mb-1 inline-block select-none ">
                {optionValue === "Facebook" ? "Facebook number" : "Your Email"}
              </span>
              <div className="relative inline-block w-full">
                <input
                  autoComplete="off"
                  type="text"
                  onChange={(e) => setEmailOrNumber(e.target.value)}
                  name="playerid"
                  className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500  px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer   small"
                  placeholder={
                    optionValue === "Facebook" ? "Enter number" : "Enter Email"
                  }
                />
              </div>
            </label>
          </div>
        </div>
        <div>
          <div className="inline-block w-full">
            <label className="relative block w-full">
              <span className="text-[14px]  text-slate-500 font-semibold mb-1 inline-block select-none ">
                Password
              </span>
              <div className="relative inline-block w-full">
                <input
                  autoComplete="off"
                  type="text"
                  onChange={(e) => setPassword(e.target.value)}
                  name="ingamepassword"
                  className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500  px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer   small"
                  placeholder="Enter password"
                />
              </div>
            </label>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <div className="inline-block mt-3 w-full">
              <label className="relative block w-full">
                <span className="text-[14px]  text-slate-500  font-semibold mb-1 inline-block select-none ">
                  {optionValue === "Facebook" ? "Facebook" : "Gmail"} backup
                  code
                </span>
                <div className="relative inline-block w-full">
                  <input
                    autoComplete="off"
                    type="text"
                    onChange={(e) => setBackupCode(e.target.value)}
                    name="securitycode"
                    className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500 px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer  "
                    placeholder="Enter backup code"
                  />
                </div>
              </label>
            </div>
          </div>
          <p className="flex text-primary justify-end mt-3">
            <a
              rel="noreferrer"
              href={`${
                optionValue === "Facebook"
                  ? "https://youtu.be/XOu0SUzN__o"
                  : "https://youtu.be/FBej9UJQiW4"
              }`}
              target="_blank"
            >
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="0"
                viewBox="0 0 24 24"
                className="flex-shrink-0"
                height="18"
                width="18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                ></path>
              </svg>{" "}
              {optionValue === "Facebook" ? "Facebook" : "Gmail"} ব্যাকআপ কোড
              বের করবেন কিভাবে?
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}

export default FreeFireInGameAcc;
