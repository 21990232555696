import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetUserProductOrdersQuery } from "../../Components/redux&rtk/features/auth/authApi";
import LoadingPage from "../../Components/Shared/LoadingPage";

function MyOrder() {
  const { auth: profile } = useSelector((state) => state);
  const [limit, setLimit] = useState(10);
  const { data, isLoading } = useGetUserProductOrdersQuery({
    email: profile?.email,
    limit: limit,
  });
  const { result: myOrders } = data || {};
  if (isLoading) return <LoadingPage />;
  return (
    <section>
      <div className="mt-10 w-full flex justify-center rounded-md">
        <section className="mt-7 pb-5 w-[90%] md:mt-0 border-t border-gray-200 md:border-none">
          <div className="!px-0 md:!px-5 md:my-7">
            <div className="bg-white">
              <div className="md:border border-gray-200 md:rounded-md pt-4 relative">
                <p className="_subtitle2 font-semibold py-1 px-4 rounded-full mb-3 text-gray-800 text-lg">
                  My Orders
                </p>
                {myOrders?.length === 0 ? (
                  <div className="overflow-auto text-gray-900">
                    <div className="flex justify-center items-center py-7 flex-col gap-3 ">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="55"
                        width="55"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path>
                        <path d="M11.412 8.586c.379.38.588.882.588 1.414h2a3.977 3.977 0 0 0-1.174-2.828c-1.514-1.512-4.139-1.512-5.652 0l1.412 1.416c.76-.758 2.07-.756 2.826-.002z"></path>
                      </svg>
                      <div className="text-center">
                        <h3 className=" font-extrabold mb-1.5">Sorry</h3>
                        <p className="">We found nothing for you.</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-gray-900 p-2 mx-auto sm:p-4 ">
                    <div className="overflow-x-auto">
                      <table className="min-w-full bg-white  text-xl">
                        <thead className="">
                          <tr className="text-left">
                            <th className="p-3 border">Index</th>
                            <th className="p-3 border">Order ID</th>
                            <th className="p-3 border">Date</th>
                            <th className="p-3 border">Product Name</th>
                            <th className="p-3 border">
                              <span className="text-red-500">
                                Gmail/Facebook
                              </span>
                              <br />
                              <span className="text-blue-500">Player ID</span>
                              <br />
                              <span className="text-cyan-500">
                                Unipin Voucher
                              </span>
                            </th>
                            <th className="p-3 border">Amount</th>
                            <th className="p-3 border">Package</th>
                            <th className="p-3 border">Status</th>
                            <th className="p-3 border">Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {myOrders?.map((each, Index) => (
                            <tr
                              key={each._id}
                              className="border-b text-gray-900  border-opacity-20 "
                            >
                              <td className="p-3 border">
                                <p>{Index + 1}</p>
                              </td>
                              <td className="p-3 border">
                                <p>#{each.orderId}</p>
                              </td>
                              <td className="p-3 border">
                                <p>{each.date}</p>
                              </td>
                              <td className="p-3 border">
                                <p>{each.productName}</p>
                              </td>
                              <td className="p-3 border">
                                <p>
                                  <span className="text-red-500">
                                    {each.productName === "FREE FIRE INGAME" &&
                                      `${each.optionValue}: `}
                                    {each.emailOrNumber}
                                  </span>
                                  <span className="text-blue-500">
                                    {each.productName !== "UNIPIN VOUCHER" &&
                                      each.productName !== "FREE FIRE INGAME" &&
                                      `PlayerID: `}
                                    {each.playerID}
                                  </span>
                                  <span className="text-cyan-500">
                                    {each.productName === "UNIPIN VOUCHER" &&
                                      `Unipin: `}
                                    {each.voucherPin}
                                  </span>
                                </p>
                              </td>
                              <td className="p-3 border">
                                <p>{each.money}</p>
                              </td>
                              <td className="p-3 border">
                                <p>{each.recharge}</p>
                              </td>
                              <td className="p-3 border">
                                <p
                                  className={`rounded-lg px-1 ${
                                    each.statusTwo === "Paid"
                                      ? "bg-green-400"
                                      : each.statusTwo === "Pending"
                                      ? "bg-red-400"
                                      : "bg-red-700"
                                  }  `}
                                >
                                  {each.statusTwo === "Paid"
                                    ? "Completed"
                                    : each.statusTwo}
                                </p>
                              </td>
                              <td className="p-3 border">
                                <p>{each.status}</p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      {myOrders.length >= 10 && (
        <div className="w-full flex items-end justify-end">
          <button
            onClick={() => setLimit(limit + 10)}
            className="bg-primary px-2 py-1 text-white rounded-lg mr-6 sm:mr-8 md:mr-14 lg:mr-20 mb-5"
          >
            See More
          </button>
        </div>
      )}
    </section>
  );
}

export default MyOrder;
