import React, { useState } from "react";
import {
  useAuthState,
  useSendPasswordResetEmail,
} from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import auth from "../../firebase.init";

function ResetPassword() {
  const [user] = useAuthState(auth);
  const [email, setEmail] = useState(null);
  const [sendPasswordResetEmail] = useSendPasswordResetEmail(auth);
  // to navigate user
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  //   send password reset email
  const sendResetPassword = async (e) => {
    e.preventDefault();
    const regexForEmail = /\S+@\S+\.\S+/;
    if (regexForEmail.test(email)) {
      await sendPasswordResetEmail(email);
      toast.success(
        "Password reset email sent. Please check your email and also spam box."
      );
      if (user) {
        navigate(from, { replace: true });
      }
      if (!user) {
        navigate("/login");
      }
    } else {
      toast.error("Please enter a valid email address.");
    }
  };
  return (
    <section className="w-[90%] my-10 mt-[150px] mx-auto flex justify-center">
      <div className="w-full sm:w-[380px]  relative border p-2 text-gray-900">
        <h3 className="text-xl font-bold">Forgot Password?</h3>
        <div className="mt-5">
          <form className="flex flex-col gap-4">
            <div>
              <div className="inline-block w-full">
                <label className="relative block w-full">
                  <div className="relative inline-block w-full">
                    <input
                      type="text"
                      name="identity"
                      autoComplete="off"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500  px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer   "
                      placeholder="Enter email address"
                    />
                  </div>
                </label>
              </div>
            </div>
            <button
              onClick={sendResetPassword}
              className="primary bg-primary text-white px-3 py-2 text-[14px] text-sm rounded-md duration-200 ease-in-out font-medium border outline-none no-underline select-non disabled:opacity-50 disabled:pointer-events-none active:opacity-60 w-full  relative"
              type="submit"
            >
              <span className="flex items-center h-full w-full justify-center gap-1.5 ">
                Send Password Reset Email.
              </span>
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
