import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const RequireNotUser = ({ children }) => {
  const { auth: profile } = useSelector((state) => state);
  const { loggedIn } = profile;
  const location = useLocation();
  if (loggedIn === true) {
    return <Navigate to="/" state={{ from: location }} replace></Navigate>;
  }
  return children;
};

export default RequireNotUser;
