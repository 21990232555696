import { signOut } from "firebase/auth";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import auth from "../../firebase.init";
import { useDispatch, useSelector } from "react-redux";
import { userLoggedOut } from "../../Components/redux&rtk/features/auth/authSlice";
import LoadingPage from "../../Components/Shared/LoadingPage";

const RequireModerator = ({ children }) => {
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.auth);
  const location = useLocation();
  if (role === undefined) return <LoadingPage />;
  if (role === "user") {
    signOut(auth);
    localStorage.removeItem("accessToken");
    dispatch(userLoggedOut());
    return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
  }
  return children;
};

export default RequireModerator;
