import React from "react";
import { useSelector } from "react-redux";
import { useGetLastWeekPaidOrdersQuery } from "../../../Components/redux&rtk/features/productAndRecharges/productAndRechargesApi";
import LoadingPage from "../../../Components/Shared/LoadingPage";
import DashboardContent from "./DashboardContent";

const LastWeekPaidOrders = () => {
  const { email } = useSelector((state) => state.auth);
  const { data, isLoading } = useGetLastWeekPaidOrdersQuery(email);
  const { result } = data || {};
  const FREEFIREINGAME = "FREE FIRE INGAME";
  const FREEFIREIDCODE = "FREE FIRE IDCODE";
  const FREEFIREPROMO = "FREE FIRE PROMO";
  const FREEFIREINDONESIA = "FREE FIRE INDONESIA";
  const PUBGMOBILE = "PUBG MOBILE";
  const LESSISMORE = "LESS IS MORE";
  const UNIPINVOUCHER = "UNIPIN VOUCHER";
  let FREEFIREINGAMEARRAY = [];
  let FREEFIREIDCODEARRAY = [];
  let FREEFIREPROMOARRAY = [];
  let FREEFIREINDONESIAARRAY = [];
  let PUBGMOBILEARRAY = [];
  let LESSISMOREARRAY = [];
  let UNIPINVOUCHERARRAY = [];
  if (isLoading) return <LoadingPage />;
  //   to push each product in it's own array
  result?.forEach((each) => {
    if (each.productName === FREEFIREINGAME) {
      FREEFIREINGAMEARRAY.push(each);
    }
    if (each.productName === FREEFIREIDCODE) {
      FREEFIREIDCODEARRAY.push(each);
    }
    if (each.productName === FREEFIREPROMO) {
      FREEFIREPROMOARRAY.push(each);
    }
    if (each.productName === FREEFIREINDONESIA) {
      FREEFIREINDONESIAARRAY.push(each);
    }
    if (each.productName === PUBGMOBILE) {
      PUBGMOBILEARRAY.push(each);
    }
    if (each.productName === LESSISMORE) {
      LESSISMOREARRAY.push(each);
    }
    if (each.productName === UNIPINVOUCHER) {
      UNIPINVOUCHERARRAY.push(each);
    }
  });

  return (
    <div>
      <p className="font-semibold py-1 px-4 rounded-full mb-3 text-blue-500 text-center underline text-xl ">
        Last Week Orders
      </p>
      <div className="my-10 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-5 ">
        <DashboardContent
          name={FREEFIREIDCODE}
          number={FREEFIREIDCODEARRAY.length}
        />
        <DashboardContent
          name={FREEFIREINDONESIA}
          number={FREEFIREINDONESIAARRAY.length}
        />
        <DashboardContent
          name={FREEFIREINGAME}
          number={FREEFIREINGAMEARRAY.length}
        />
        <DashboardContent
          name={FREEFIREPROMO}
          number={FREEFIREPROMOARRAY.length}
        />
        <DashboardContent name={PUBGMOBILE} number={PUBGMOBILEARRAY.length} />
        <DashboardContent name={LESSISMORE} number={LESSISMOREARRAY.length} />
        <DashboardContent
          name={UNIPINVOUCHER}
          number={UNIPINVOUCHERARRAY.length}
        />
        <DashboardContent
          name={"Total Orders"}
          number={
            FREEFIREIDCODEARRAY.length +
            FREEFIREINDONESIAARRAY.length +
            FREEFIREINGAMEARRAY.length +
            FREEFIREPROMOARRAY.length +
            PUBGMOBILEARRAY.length +
            LESSISMOREARRAY.length +
            UNIPINVOUCHERARRAY.length
          }
        />
      </div>
    </div>
  );
};

export default LastWeekPaidOrders;
