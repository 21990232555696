import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightToBracket,
  faUser,
  faCartArrowDown,
  faWallet,
  faSquare,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../image/logo.png";
import NavbarLink from "./NavbarLink";
import menu from "../../image/icons-menu.svg";
import { useDispatch, useSelector } from "react-redux";
import { userLoggedOut } from "../redux&rtk/features/auth/authSlice";
import { signOut } from "firebase/auth";
import auth from "../../firebase.init";
import { useAuthState } from "react-firebase-hooks/auth";

function Navbar() {
  const [user] = useAuthState(auth);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { auth: profile } = useSelector((state) => state);
  const { name, role, photo, loggedIn } = profile;
  // console.log(role);
  // it's just a crazy stuff  to hide the navbar when click outside of navbar in mobile view.
  document.onclick = function (e) {
    // console.log(e.target);
    // console.log(e.target.id);
    if (
      e.target.id !== "sidebar" &&
      e.target.id !== "sidebarOpen" &&
      e.target.id !== "nav-div-one" &&
      e.target.id !== "side-li" &&
      e.target.id !== "side-ul" &&
      e.target.id !== "side-menu" &&
      e.target.id !== "side-10"
    ) {
      setOpen(false);
    }
  };

  // to logout user
  const logOutUser = () => {
    signOut(auth);
    localStorage.removeItem("accessToken");
    setOpen(!open);
    dispatch(userLoggedOut());
  };
  return (
    <nav className="top-0 bg-white z-10 fixed ">
      <div className="navbar top-0 bg-white flex-row-reverse md:flex-row w-[90%] mx-auto py-5">
        <div className={`${open ? "flex-[0]" : "flex-[0.6]"} md:flex-[0.2]`}>
          <Link to="/" className="px-2 text-4xl">
            {/* Logo */}
            <img loading="lazy" src={logo} alt="log" className="" />
          </Link>
        </div>
        {/* Search Field */}
        <div className="flex-[0.7]  hidden md:flex">
          <div className="form-control w-[100%]">
            <div className="input-group">
              <input
                autoComplete="off"
                type="text"
                placeholder="Search for products"
                className="border-2 px-3 w-full text-black input-bordered focus:outline-[#ddd6d7] focus:bg-white bg-[#e4e1e2]"
              />
              <button className="btn bg-primary hover:bg-[#16c6d2]  btn-square">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* /***********************************
          Right Side from medium px (tab)
         ********************************************/}
        <div className="flex-[0.1] hidden md:flex justify-end">
          {loggedIn === true ? (
            <div className="flex  items-center p-2 space-x-4">
              <div className="dropdown dropdown-hover">
                <img
                  loading="lazy"
                  tabIndex="0"
                  src={photo || user?.photoURL}
                  alt=""
                  className="w-12 h-12 rounded-full "
                />
                <ul
                  tabIndex="0"
                  className="dropdown-content left-[-250px] text-white mr-52 bg-primary menu p-2 shadow rounded-box w-[300px]"
                >
                  <div id="nav-div" className="h-full py-7 px-5 space-y-2 w-60">
                    {/* /***************************************
                         Drowdown menu : When user is logged
                   ********************************************/}
                    {loggedIn === true && (
                      <div className="flex items-center p-2 space-x-4">
                        <img
                          loading="lazy"
                          src={photo || user?.photoURL}
                          alt=""
                          className="w-12 h-12 rounded-full "
                        />
                        <div>
                          <h2 className="text-xl font-semibold">{name}</h2>
                        </div>
                      </div>
                    )}
                    <div className="divide-y divide-gray-700">
                      {loggedIn === true && (
                        <ul
                          id="one-number"
                          className="pt-2 pb-4 space-y-1 text-sm"
                        >
                          {/* My profile */}
                          <NavbarLink
                            faI={faUser}
                            link="/profile"
                            name="My Profile"
                          />
                          {/*************************************
                         Menu: depends on user is admin or not
                         *************************************/}

                          {/* // If user is not admin(normal user) */}

                          {role === "user" && (
                            <div>
                              {/* My Wallet */}
                              <NavbarLink
                                faI={faWallet}
                                link="/wallet"
                                name="My Wallet"
                              />
                              {/* My Order */}
                              <NavbarLink
                                faI={faCartArrowDown}
                                link="/order"
                                name="My Order"
                              />
                              {/* Support */}
                              <li id="side-li" className="bg-primary">
                                <a
                                  href="https://wa.me/message/3IZHMZJUKJWSE1"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="w-fit pr-5 flex items-center p-2 space-x-3 rounded-md"
                                >
                                  <FontAwesomeIcon icon={faHeadset} />
                                  <span>Support</span>
                                </a>
                              </li>
                            </div>
                          )}

                          {/* * If he is ADMIN! */}
                          {role !== "user" && (
                            <div>
                              {/* All Orders of Game */}
                              <NavbarLink
                                faI={faCartArrowDown}
                                link="/all-product-order"
                                name="All Orders"
                              />
                              {role === "admin" && (
                                <>
                                  {/* All Recharge Options */}
                                  <NavbarLink
                                    faI={faSquare}
                                    link="/all-recharge-options"
                                    name="Recharge Packages"
                                  />
                                  {/* All Voucher Code Options */}
                                  <NavbarLink
                                    faI={faSquare}
                                    link="/voucher-code"
                                    name="Voucher Code"
                                  />
                                  {/* All Slider Contents */}
                                  <NavbarLink
                                    faI={faSquare}
                                    link="/slider-contents"
                                    name="Slider Contents"
                                  />
                                  {/* Dashboard */}
                                  <NavbarLink
                                    faI={faSquare}
                                    link="/dashboard"
                                    name="Dashboard"
                                  />
                                </>
                              )}
                            </div>
                          )}
                          {/* Logout */}
                          <li className="bg-primary">
                            <Link
                              onClick={logOutUser}
                              to="/order"
                              rel="noopener noreferrer"
                              href="#"
                              className="w-fit pr-5 flex items-center p-2 space-x-3 rounded-md"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                className="w-5 h-5 fill-current "
                              >
                                <path d="M440,424V88H352V13.005L88,58.522V424H16v32h86.9L352,490.358V120h56V456h88V424ZM320,453.642,120,426.056V85.478L320,51Z"></path>
                                <rect
                                  width="32"
                                  height="64"
                                  x="256"
                                  y="232"
                                ></rect>
                              </svg>
                              <span>Logout</span>
                            </Link>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          ) : (
            /***********************************
          Here the condition's second part 
         *************************************/

            <ul className="px-4 menu-horizontal p-0">
              <li className="p-2  text-gray-900 hover:text-primary">
                <Link to="/register">Register</Link>
              </li>
              <li className="p-2 text-gray-900 hover:text-primary">
                <Link to="/login">Login</Link>
              </li>
            </ul>
          )}
        </div>
        {/* /***********************************
          Sidebar for mobile
         *************************************/}
        <div
          className={`dropdown ${
            open ? "flex-[1]" : "flex-[0.4]"
          } text-start md:hidden`}
        >
          <img
            loading="lazy"
            onClick={() => setOpen(!open)}
            id="side-menu"
            className="w-8 cursor-pointer"
            src={menu}
            alt="menu"
          />
          <ul
            id="sidebar"
            className={`bg-primary text-white md:hidden flex top-[-35px]  flex-col items-center pb-12 gap-3 absolute z-[100] transition-all duration-500 pr-9  text-center h-[110vh]  ease-linear ${
              open ? "top-[-35px] left-[-28px] w-full " : "left-[-490px]"
            }`}
          >
            <div
              id="nav-div-one"
              className="h-full relative py-7 px-5 space-y-2 w-60"
            >
              <button
                onClick={() => setOpen(!open)}
                className="absolute right-[-40px] top-[40px] bg-primary border-2 rounded-full px-[15px] py-3"
              >
                <FontAwesomeIcon
                  id="side-1"
                  className="font-bold text-xl"
                  icon={faArrowLeftLong}
                ></FontAwesomeIcon>
              </button>

              {/* /***********************************
          Sidebar Profile Section : When user is logged
         ********************************************/}

              {loggedIn === true && (
                <div
                  id="side-10"
                  className="flex  items-center pl-5 p-2 space-x-4"
                >
                  <img
                    loading="lazy"
                    id="side-li"
                    src={photo || user?.photoURL}
                    alt=""
                    className="w-12 h-12 rounded-full "
                  />
                  <div>
                    <h2 id="side-li" className="text-lg font-semibold">
                      {name}
                    </h2>
                  </div>
                </div>
              )}
              {/* /***********************************
          Sidebar Menu Section : When user is logged
         ********************************************/}
              <div className="divide-y divide-gray-700">
                {loggedIn === true ? (
                  <ul id="side-ul" className="pt-2 pl-5 pb-4 space-y-1 text-sm">
                    {/* My profile */}
                    <NavbarLink
                      faI={faUser}
                      link="/profile"
                      name="My Profile"
                    />
                    {role === "user" && (
                      <div>
                        {/* My Wallet */}
                        <NavbarLink
                          faI={faWallet}
                          link="/wallet"
                          name="My Wallet"
                        />
                        {/* My Order */}
                        <NavbarLink
                          faI={faCartArrowDown}
                          link="/order"
                          name="My Order"
                        />
                        {/* Support */}
                        <li id="side-li" className="bg-primary">
                          <a
                            href="https://wa.me/message/3IZHMZJUKJWSE1"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="w-fit pr-5 flex items-center p-2 space-x-3 rounded-md"
                          >
                            <FontAwesomeIcon icon={faHeadset} />
                            <span>Support</span>
                          </a>
                        </li>
                      </div>
                    )}
                    {/* If he is user is not a normal user */}
                    {role !== "user" && (
                      <div>
                        {/* All Orders of Game */}
                        <NavbarLink
                          faI={faCartArrowDown}
                          link="/all-product-order"
                          name="All Orders"
                        />
                        {role === "admin" && (
                          <>
                            {/* All Recharge Options */}
                            <NavbarLink
                              faI={faSquare}
                              link="/all-recharge-options"
                              name="Recharge Packages"
                            />
                            {/* All Recharge Options */}
                            <NavbarLink
                              faI={faSquare}
                              link="/voucher-code"
                              name="Voucher Codes"
                            />
                            {/* All Slider Contents */}
                            <NavbarLink
                              faI={faSquare}
                              link="/slider-contents"
                              name="Slider Contents"
                            />
                            {/* Dashboard */}
                            <NavbarLink
                              faI={faSquare}
                              link="/dashboard"
                              name="Dashboard"
                            />
                          </>
                        )}
                      </div>
                    )}
                  </ul>
                ) : (
                  // *********************************************
                  //Sidebar Profile Section : When user isn't logged
                  //  ********************************************
                  <ul id="side-ul" className="pt-2 pb-4 space-y-1 pl-5 text-sm">
                    {/* Login */}
                    <NavbarLink
                      faI={faArrowRightToBracket}
                      link="/login"
                      name="Login"
                    />
                    {/* Register */}
                    <NavbarLink
                      faI={faArrowRightToBracket}
                      link="/register"
                      name="Register"
                    />
                  </ul>
                )}
                {/* /***********************************
          Logout Section : When user is logged
         ********************************************/}
                {loggedIn === true && (
                  <ul id="side-ul" className="pt-4 pl-5 pb-2 space-y-1 text-sm">
                    <li
                      id="side-9"
                      onClick={logOutUser}
                      rel="noopener noreferrer"
                      href="#"
                      className="flex w-fit pr-5 bg-primary cursor-pointer items-center p-2 space-x-3 rounded-md"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="w-5 h-5 fill-current "
                      >
                        <path d="M440,424V88H352V13.005L88,58.522V424H16v32h86.9L352,490.358V120h56V456h88V424ZM320,453.642,120,426.056V85.478L320,51Z"></path>
                        <rect width="32" height="64" x="256" y="232"></rect>
                      </svg>
                      <span>Logout</span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
