import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useGetRechargePackageQuery } from "../redux&rtk/features/productAndRecharges/productAndRechargesApi";

function FreeFireIndonesia({
  setMoney,
  setRecharge,
  recharge,
  money,
  balance,
}) {
  const productName = "FREE FIRE INDONESIA";
  const { data } = useGetRechargePackageQuery(productName);
  const { result: allrecharge } = data || {};
  return (
    <section className="order_box_bottom">
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 gap-2.5">
        {allrecharge?.map((each) => (
          <div
            key={each._id}
            onClick={() => {
              setRecharge(each.name);
              setMoney(each.amount);
            }}
            className="rounded relative  cursor-pointer select-none duration-200 border flex items-stretch border-gray-200 hover:border-gray-400 justify-center"
          >
            <div className="px-1 md:px-2 flex items-center w-full ">
              <div className="flex  justify-center gap-2 items-center w-full">
                <span className=" !text-sm md:!text-base font-semibold text-center">
                  <span className="px-1 py-2.5 inline-block">{each.name}</span>
                  <span className="!text-xs !font-normal text-primary-500">
                    {each.amount === 0 ? (
                      <span className="text-[10px]"> Out of Stock</span>
                    ) : (
                      <span>৳ {each.amount}</span>
                    )}
                  </span>
                </span>
              </div>
            </div>
            {/*  */}
            {recharge === each.name && (
              <FontAwesomeIcon
                className="absolute top-[-1px] left-[-1px] rounded-tl-sm bg-primary py-1 px-2 text-white"
                icon={faCheck}
              />
            )}
          </div>
        ))}
      </div>
      {balance < money && (
        <p className="pt-4 text-xs text-red-600">
          You do not have enough money to order this package, Please Add Money
          or choose another package.
        </p>
      )}
    </section>
  );
}

export default FreeFireIndonesia;
