import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const apiSlice = createApi({
  reducerPath: "topuphero",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://server.topuphero.com/api",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["mobile", "order", "add", "delete"],
  endpoints: (builder) => ({}),
});

// https://server.topuphero.com/
