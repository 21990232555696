import React, { useEffect, useState } from "react";
import AddNewVoucherCode from "../../Components/Modals/AddNewVoucherCode";
import ShowVoucherCard from "./ShowVoucherCard";
function VoucherCodeDetails() {
  const [rechargeOptions, setrechargeOptions] = useState([]);
  const [nameOfProduct, setNameOfProduct] = useState("UNIPIN VOUCHER");
  const [state, setState] = useState("Remain");
  const [addVoucher, setAddVoucher] = useState(null);
  const [update, setUpdate] = useState(null); // to update voucher code; when a new code added
  useEffect(() => {
    fetch(
      `https://server.topuphero.com/api/all-recharge-options/${nameOfProduct}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => setrechargeOptions(data.result));
  }, [nameOfProduct, state, update]);
  return (
    <section className="pt-2 w-[90%] text-gray-900 mx-auto rounded-md">
      <div className="mt-10 md:mt-10 border-t border-gray-200 md:border-none">
        <div className="!px-0 md:!px-5 md:my-7">
          <div className="bg-white flex flex-col">
            <div className="border-gray-200 md:rounded-md pt-4 relative">
              <div className="flex justify-between items-center">
                <p className="font-semibold py-1 px-4 rounded-full mb-3 text-primary text-2xl ">
                  <span>Voucher Codes</span>{" "}
                </p>
                <div className="flex">
                  <p className="font-semibold py-1 px-4 rounded-full mb-3 text-gray-800 text-lg">
                    <select
                      onChange={(e) => setState(e.target.value)}
                      className="select text-gray-900 text-[15px] select-primary w-full max-w-xs"
                    >
                      <option selected>Remain</option>
                    </select>
                  </p>
                  <p className="font-semibold py-1 px-4 rounded-full mb-3 text-gray-800 text-lg">
                    <select
                      onChange={(e) => setNameOfProduct(e.target.value)}
                      className="select text-gray-900 text-[15px] select-primary w-full max-w-xs"
                    >
                      <option selected>UNIPIN VOUCHER</option>
                    </select>
                  </p>
                </div>
              </div>
              <div className="">
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white  text-xl">
                    <thead className="">
                      <tr className="text-left">
                        <th className="p-3 border">Index</th>
                        <th className="p-3 border">Recharge Pack Name</th>
                        <th className="p-3 border">Amount</th>
                        <th className="p-3 border">Vouchers</th>
                        <th className="p-3 border">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rechargeOptions?.map((each, Index) => (
                        <tr
                          key={each._id}
                          className="border-b  border-opacity-20 "
                        >
                          <td className="p-3 border">
                            <p>{Index + 1}</p>
                          </td>
                          <td className="p-3 border">
                            <p>{each.name}</p>
                          </td>
                          <td className="p-3 border">
                            <p>{each.amount}</p>
                          </td>
                          <td className="p-3 border">
                            <ShowVoucherCard
                              state={state}
                              update={update}
                              setUpdate={setUpdate}
                              rechargeId={each?._id}
                            />
                          </td>
                          <td className="p-3 border">
                            {state === "Remain" ? (
                              <label
                                htmlFor="add-new-voucher-code"
                                onClick={() => setAddVoucher(each)}
                                className="bg-red-300 rounded-lg px-2 cursor-pointer py-1 w-fit"
                              >
                                Add New Voucher
                              </label>
                            ) : (
                              <label>Nothing</label>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {addVoucher && (
        <AddNewVoucherCode
          setUpdate={setUpdate}
          rechargeOption={addVoucher}
          setAddVoucher={setAddVoucher}
        />
      )}
    </section>
  );
}

export default VoucherCodeDetails;
