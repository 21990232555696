import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { toast } from "react-toastify";

export default function ShowVoucherCard({
  state,
  update,
  rechargeId,
  setUpdate,
}) {
  const [user] = useAuthState(auth);
  const [voucherCodes, setVoucherCodes] = useState([]);
  useEffect(() => {
    fetch(
      `https://server.topuphero.com/api/all-voucher-codes/${rechargeId}/${user?.email}/${state}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setVoucherCodes(data.result);
      });
  }, [update, rechargeId, user?.email, state]);
  const handleDelete = (id) => {
    fetch(
      `https://server.topuphero.com/api/admin/delete-voucher-code?id=${id}&adminEmail=${user?.email}`,
      {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.result) {
          toast.success(`Voucher code is deleted.`);
          setUpdate(data);
        }
      });
  };
  return (
    <div>
      <b>Total : {voucherCodes?.length}</b> <br />
      <>
        {voucherCodes?.map((each) => (
          <p key={each?._id}>
            {each?.code}{" "}
            <button onClick={() => handleDelete(each?._id)}>
              <FontAwesomeIcon icon={faDeleteLeft} />
            </button>
          </p>
        ))}
      </>
    </div>
  );
}
